import React from 'react'

/* Style */
import '../styles/Contact.css'

const Contact = () => {
  return (
    <div className='contact'>
      <h1>Contact Us</h1>
      <div className='contact-address'>
        <h2>Address</h2>
        <p>Organize Sanayi Bölgesi, Bilim Cad. Yazılım Kule </p>
        <p>No:5/205 Odunpazarı</p>
        <p>Eskişehir, Türkiye</p>
      </div>
      <div className='contact-map'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.424649742853!2d30.63298831563453!3d39.75257310368624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cc3944cd97f7f7%3A0x12b9b1f5a6ea1619!2zWWF6xLFsxLFta3VsZQ!5e0!3m2!1str!2str!4v1667082727455!5m2!1str!2str"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title='maps-address'
        />
      </div>
    </div>
  )
}

export default Contact