import React from 'react'

/* Style */
import '../styles/Raman.css'

const Raman = () => {
  return (
    <div className='raman'>
      <h1>Raman Spectroscopy</h1>
      <div className='raman-paragraphs'>
        <p>A Raman spectrometer is an instrument that measures the spectrum of light scattered from a sample to gather information about molecules and chemical bonds in the sample. The spectrum from a Raman spectrometer serves as a fingerprint which can be used to identify or characterize a substance. This makes it very useful in many areas of research as well as for applications like authentication, quality control, and biopharmaceutical Product development. At Sonacl Technology, we focus on developing the most sensitive and reliable Raman spectrometers possible.</p>
      </div>
    </div>
  )
}

export default Raman