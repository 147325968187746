import React from 'react'

/* Style */
import '../styles/About.css'

const About = () => {
  return (
    <div className='about'>
      <h1>ABOUT US</h1>
      <div className='about-paragraphs'>
        <p>
          Sonacl is devoted to realize high performance spectrometers at affordable prices. We will be doing this by bringing the highest performance components together with intelligent and innovative designs.
        </p>
        <p>
          As a result of intensive market research, Sonacl determined a Raman spectrometer as the first product to be developed. This benchtop spectrometer will be followed by a Raman microscope, and a Raman spectrometer as an electron microscope add-on.
        </p>
        <p>
          The technical knowledge, market knowledge, market recognition and business management knowledge of its founders, who are senior engineers, provide our company with very important advantages in competition.
        </p>
        <p>
          The Raman spectrometer that Sonacl is currently developing will be a superior product in terms of sensitivity/price. It is also expected to give much more reproducible results than normal Raman spectrometers in the analysis of matter in optically scattering media.
        </p>
        <p>
          Sonacl is a company that has the potential to progress rapidly in developing and marketing the Raman spectrometer with these technical advantages.
        </p>
      </div>
    </div>
  )
}

export default About