import React from 'react'

import HeroImage from '../assets/images/hero-image.jpg';

/* Style */
import '../styles/Home.css'

const Home = () => {
  return (
    <div className='hero-section'>
      <div className="hero-container">
        <div className='hero-left'>
          <h1 className='hero-text'>
            Accurate and Precise<br />
            Spectroscopy
          </h1>
        </div>
        <div className='hero-right'>
          <img className='hero-image' src={HeroImage} alt='hero' ></img>
        </div>
      </div>
    </div>
  )
}

export default Home