import React from 'react'

/* Style */
import '../styles/Technology.css'

const Technology = () => {
  return (
    <div className='technology'>
      <h1>Our Technology</h1>
      <div className='technology-paragraphs'>
        <p>Sonacl is devoted to realize high performance spectrometers at affordable prices. We will be doing this by bringing together the highest performance components with intelligent and innovative designs.</p>
        <p>What distinguishes Sonacl is its ingenious introduction of diffractive optics into spectroscopy. The major disadvantage of Raman spectroscopy is that the Raman scattering effect is inherently weak. On average, out of 10 million photons interacting with the sample, only one is scattered by the Raman effect. The very low Raman signals require the use of highly sensitive, often cooled and therefore expensive sensors. In the devices we obtain high signals thanks to our proprietary optical design and signal acquisition module.</p>
        <p>Our method also contributes to reducing the effects of sample-induced optical scattering on measurement results. The scattering of the laser beam inside and from the sample during Raman analysis can seriously complicate the sensitive detection and quantitative analysis of the chemicals in the sample. It is rarely possible to obtain reproducible results in quantitative Raman analyzes on powders, solid or liquid suspensions, and all optically inhomogeneous mixtures. For this reason, many Raman analyzes are often done qualitatively, even when the demand is for quantitative results.</p>
        <p>In our device, we optically enhance the signal by our proprietary optical system for solving this problem.</p>
      </div>
    </div>
  )
}

export default Technology