import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom'

/* Pages */
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Raman from "./pages/Raman";
import Technology from "./pages/Technology";

/* Components */
import Navbar from "./components/NavbarResponsive"; // Old navbar
import Nav from "./components/Navbar/Navbar"; // New responsive navbar

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/raman" element={<Raman />} />
          <Route path="/technology" element={<Technology />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
