import React, { useState } from "react";
import './Navbar.css';

import { Link } from "react-router-dom";

import SonaclLogo from "../../assets/images/logo_op2.png";
import { FaBars } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo"><a href="/"><img src={SonaclLogo} alt='logo' width='160' /></a></div>
      <div className="navbar-icon" onClick={toggleNav}>
        <FaBars />
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/technology">Technology</Link></li>
          <li><Link to="/raman">Raman</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;